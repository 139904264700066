var user = 'users';
var password = 'easylinkuser';

Sbi.sdk.services.setBaseUrl({
   protocol: 'http'     
   , host: '54.37.212.235'
   , port: '8181'
   , contextPath: 'knowage'
   , controllerPath: 'servlet/AdapterHTTP'  
});


execTest2 = function() {
   var html = Sbi.sdk.api.getDocumentHtml({
        documentLabel: 'Taux_penetration'
       , executionRole: '/spagobi/user'
       //, parameters: {ELAB_ID: 'ELA_T_ANVOPOD_1', TIPO_AN_ID: 'ANVOFATT'}
       , displayToolbar: false
       , canResetParameters: false
       , iframe: {
           height: '800px'
           , width: '100%'
           , style: 'border: none;'
       }
   });
  
   
   document.getElementById('targetDiv').innerHTML = html;
};

   /*
   * the callback invoked uppon request termination
   *
   * @param result the server response
   * @param args parameters sent to the server with the original request
   * @param seccess true if the service has been executed by the server in a succesfull way
   */ 
   var cb = function(result, args, success) {
     
       if(success === true) {
         execTest2();
       } else {
           alert('ERROR: Wrong username or password');
       }
   };

   

 doLogin = function() {

   Sbi.sdk.api.authenticate({
     params : {
       user : user,
       password : password
     }

     ,
     callback : {
       fn : cb,
       scope : this
     }
   });
 };